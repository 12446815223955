<template lang='pug'>
  list(pageType='connect')
</template>

<script>
import List from '../components/System/List.vue';
export default {
    components: {
        List,
    },
};
</script>
